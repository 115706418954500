import React, { Component } from 'react'

//Componente que muestra los clientes que tiene una empresa
class TripsListTotal extends Component {
 
  async componentDidMount(){
    let init = this.props.listInit
    if (init === null || init === undefined || init < 0){
      init = 0
    }
    let end = init +10
    await this.displayTrips(init,end)
  }

  async componentDidUpdate() {
    let init = this.props.tripsListInit
    if (init === null || init === undefined || init < 0){
      init = 0
    }
    let end = init +10
    
    if (this.state.pagesTrips ===""){
      await this.displayTrips(init,end)
    }
  }


  async formatDate(date, showTime) {
    try {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      if (day.length < 2) {
        day = "0" + day;
      }
      //let year = d.getFullYear();
      let hours = d.getHours().toString();
      if (hours.length < 2) {
        hours = "0" + hours;
      }
      let minutes = d.getMinutes().toString();
      if (minutes.length < 2) {
        minutes = "0" + minutes;
      }
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      let dateToShow;
      if (showTime) {
        dateToShow = day + "-" + month + " " + hours + ":" + minutes;
      } else {
        dateToShow = day + "-" + month;
      }
      return dateToShow;
    } catch (err) {
      alert("Error al formatear la fecha");
    }
  }

  async displayTrips(init, end) {

    try {
      let tripsList = await this.props.tripsTotal;
      // tripsList = await tripsList.filter((trip) => trip.trip_date !== "");

     

      let pagesTrips = Math.ceil(tripsList.length / 10);
      //let pageClient = this.state.pageClient > pagesClients ? pagesClients : this.state.pageClient;
      // let initClients = (pageClient - 1) * 10;
      // let endClients = pageClient * 10;
      let pageTrip = Math.ceil(end/10)

      let tripsToshow = await tripsList.slice(init, end);

      

      for (let i = 0; i < tripsToshow.length; i++) {
        let dateInt
        //if (tripsToshow[i]["trip_date"] === "") {
          // get tripsToshow[i]["signedBySenderDate"] from "2024-10-03T06:00:00"  string format to timestamp 
          dateInt = new Date(tripsToshow[i]["signedBySenderDate"]).getTime();

        // }else{
        //   dateInt = parseInt(tripsToshow[i]["trip_date"]) * 86400000 + Date.parse(new Date(2022, 0, 1));
        // }
        
        let dateFormat = await this.formatDate(dateInt, false);
        tripsToshow[i]["dateToShow"] = dateFormat;
        tripsToshow[i]["co2Format"]= Math.round(tripsToshow[i]["co2"]/100000,2) + "  kg de co2";
        tripsToshow[i]["co2Intensity"]= Math.round(tripsToshow[i]["co2"]/100000,2)/Math.round(tripsToshow[i]["details_list"][5]/1000,2) + "  kg de co2";

        let modality = tripsToshow[i]["mode"];
        let modalityFormat;

        switch (modality) {
          case "complete":
            modalityFormat = "Completa";
            break;
          case "empty":
            modalityFormat = "En vacío";
            break;
          case "partial":
            modalityFormat = "Grupaje";
            break;
          default:
            modalityFormat = "No indicado";
            break;
        }

        tripsToshow[i]["modeToShow"] = modalityFormat;

        let distance = parseInt(tripsToshow[i]["minAmount"]);
        let duration;
        if (isNaN(distance)) {
          duration = 0;
        } else {
          duration = distance / 60;
        }
        var durationFormat;

        let durHours = Math.floor(duration / 60);
        let durMinutes = String(Math.floor(duration - durHours * 60));
        if (durMinutes.length < 2) {
          durMinutes = "0" + durMinutes;
        }
        durationFormat = String(durHours) + ":" + durMinutes;
        tripsToshow[i]["durationFormat"] = durationFormat;
      }


      this.setState({
        pagesTrips: pagesTrips,
        pageTrip: pageTrip,
        tripsToshow: tripsToshow,
      });

      this.props.editTripsListInit(init);
      return pagesTrips;
    // } catch (err) {
    //   alert("Error al mostrar los viajes");

    // }

    //let pageTrip = Math.ceil(end / 10);
    this.setState({ pageTrip: pageTrip });
    this.setState({ tripsToshow: tripsToshow });
    this.props.editTripsListInit(init);
    return pagesTrips;
    } catch (err) {
      alert("Error al mostrar los viajes")
    }
  }
    
  constructor(props) {
    super(props)
    this.state = {
      showCreation: false,
      trips: [],
      pagesTrips: "",
      pageTrip: 1,
      numReloads: 0,
      tripsToshow: [],
      successMsg: "",  // Mensaje de éxito para la eliminación
    };
    this.displayTrips= this.displayTrips.bind(this);
  }
  handleCreateTrip = () => {
    this.setState({ showCreation: true });
  };
  render() {
     try{
      //Comprueba si es ordenador o móvil para mostrarlo de una u otra forma
      if(this.props.device === 'desktop'){

        //Comprueba si debe crear varias páginas o no
        if (this.state.pagesTrips > 1 && this.state.pageTrip !== "") {
          var page = this.state.pageTrip
          var pages = this.state.pagesTrips
          var init = (page-1)*10
          var end = page*10
          var paginatorTrips = 
            <div className="wid-100 te-ali-cen">
              <div> Página {page} de {pages} </div>
              <table className="wid-100">
                <tbody className="wid-100">
                  <tr className="wid-100">
                    <td className="wid-25"></td>
                    <td className="wid-25">
                      <button onClick={(event) => {
                        event.preventDefault()
                        if(page>1){
                          this.setState({page: page-1})
                          init = init - 10
                          end = end - 10
                        }
                        this.displayTrips(init,end)
                        this.props.actualizarEstadoYAlmacenar()
                      }} 
                      className="btn btn-outline-secondary">Anterior </button>
                    </td>
                    <td className="wid-25">
                      <button onClick={(event) => {
                        event.preventDefault()
                        if(page<pages){
                          this.setState({page: page+1})
                          init = init + 10
                          end = end + 10
                        }
                        this.displayTrips(init,end)
                        this.props.actualizarEstadoYAlmacenar()
                      }}
                      className="btn btn-outline-secondary">Siguiente </button>
                    </td>
                    <td className="wid-25"></td>
                  </tr>
                </tbody>
              </table>
            </div>
        }else{
          <div></div>
        }
  
        //Si la empresa no tiene empleados asignados
        if(this.props.tripsList.length === 0){
          return (
            <div>
            <div id= "content" className="mt-3">
              
              <p className="te-ali-cen">  No tienes viajes creados aun aún, para cargarlos pulsa "Crear Empleado" y sube su correspondiente TGD</p>
              
            </div>
            
            </div>
          )
        }


        let displayList =

        <div id="content" className="mt-3">
          <div className="card mb-4">
            {paginatorTrips}
            <div className="card-body">
              {/* Mostrar el mensaje de éxito */}
              {this.state.successMsg && <div className="success-message">{this.state.successMsg}</div>}


              <table className="table fs-12">

                <thead>
                  <tr>
                    <th scope="col">Origen</th>
                    <th scope="col">Destino</th>
                    <th scope="col">Duración</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">CO2</th>
                    <th scope="col">Intensidad de emisiones</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody id="productList">
                  {this.state.tripsToshow.map((trip, key) => {
                    var statusText = "";
                    var statusStyle = "green";

                    return (
                      <tr key={key} className='w-100'>
                        <td className='w-17'>{this.state.tripsToshow[key]["origin"]}</td>
                        <td className='w-17'>{this.state.tripsToshow[key]["destination"]}</td>
                        <td className='w-17'>{this.state.tripsToshow[key]["durationFormat"]}</td>
                        <td className='w-17'>{this.state.tripsToshow[key]["dateToShow"]}</td>
                        <td className='w-17'>{this.state.tripsToshow[key]["co2Format"]}</td>
                        <td className='w-17'>{this.state.tripsToshow[key]["co2Intensity"]}</td>
                        <td className='w-5 text-right h-50'>  {
                                {}
                                  ? <button
                                    className="btn btn-outline-secondary fs-10 h-100"
                                    onClick={(event) => {
                                      this.props.showEditTripFields(this.state.tripsToshow[key]["id"])
                                    }}
                                  >
                                    Editar
                                  </button>
                                  : null
                              }</td>
                        <td className='w-5 text-right h-50'>  {
                                {}
                                  ? <button
                                    className="btn btn-outline-secondary fs-10 h-100"
                                    onClick={(event) => {
                                      
                                       this.props.hideTrip(this.state.tripsToshow[key]["id"], true); 
                                    }}
                                  >
                                    Borrar
                                  </button>
                                  : null
                              }</td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
  
        //Creamos el box con la lista de empleados y unos títulos descriptivos
        return (
          <div id= "content" className="mt-3">
            {/* <form className="mb-3"  onSubmit={(event) => {
              event.preventDefault()
              let _tripSearched = this._tripSearched.value 
              this.props.search(empl.id,0,0,{},false,"", false, false);
            }}></form> */}
            {displayList}
            {paginator}
          </div>
        )
      } else {
        if (this.state.pagesTrips > 1 && this.state.page !== "") {
          var page = this.state.pageTrip
          var pages = this.state.pagesTrips
          var init = (page-1)*10
          var end = page*10
          var paginatorTrips = 
            <div className="wid-100 te-ali-cen">
              <div> Página {page} de {pages} </div>
              <table className="wid-100">
                <tbody className="wid-100">
                  <tr className="wid-100">
                    <td className="wid-25"></td>
                    <td className="wid-25">
                      <button onClick={(event) => {
                        event.preventDefault()
                        if(page>1){
                          this.setState({page: page-1})
                          init = init - 10
                          end = end - 10
                        }
                        this.displayTrips(init,end)
                        this.props.actualizarEstadoYAlmacenar()
                      }}
                      className="btn btn-outline-secondary">Anterior </button>
                    </td>
                    <td className="wid-25">
                      <button onClick={(event) => {
                        event.preventDefault()
                        if(page<pages){
                          this.setState({page: page+1})
                          init = init + 10
                          end = end + 10
                        }
                        this.displayTrips(init,end)
                        this.props.actualizarEstadoYAlmacenar()
                      }}
                      className="btn btn-outline-secondary">Siguiente </button>
                    </td>
                    <td className="wid-25"></td>
                  </tr>
                </tbody>
              </table>
            </div>
        }else{
          <div></div>
        }

        let displayList
        
        if(this.state.tripsToshow.length === 0){
          displayList = (
            
         
            <div>
            <div id= "content" className="mt-3">
              <br></br>
              <p className="te-ali-cen">   No tienes viajes asignados para estas fechas, para crearlo pulsa "Crear Viaje" y sube su correspondiente TGD</p>
            </div>
            
            </div>
          )
        }else{

         displayList = (
          <div id="content" className="mt-3">
            <div className="card mb-4">
              {paginatorTrips}
              <div className="card-body">
              
                <table className="table fs-12">

                  <thead>
                    <tr>
                      <th scope="col">Origen</th>
                      <th scope="col">Destino</th>
                      <th scope="col">Tiempo</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">CO2</th>
                      <th scope="col">Intensidad de emisiones</th>
                    </tr>
                  </thead>
                  <tbody id="productList">
                    {this.state.tripsToshow.map((trip, key) => {
                      var statusText = "";
                      var statusStyle = "green";

                      return (

                         <tr key={key} className='w-100'>
                          <td className='w-17'>{this.state.tripsToshow[key]["origin"]}</td>
                          <td className='w-17'>{this.state.tripsToshow[key]["destination"]}</td>
                          <td className='w-17'>{this.state.tripsToshow[key]["durationFormat"]}</td>
                          <td className='w-17'>{this.state.tripsToshow[key]["dateToShow"]}</td>
                          <td className='w-17'>{this.state.tripsToshow[key]["co2Format"]}</td>
                          <td className='w-17'>{this.state.tripsToshow[key]["co2Intensity"]}</td>
                          <td style={{ width: "5%", textAlign: "right", height: "50px" }}>
                            {" "}
                            {{} ? (
                              <button
                                className="btn btn-outline-secondary"
                                style={{ fontSize: "10px", height: "100%" }}
                                onClick={(event) => {
                                  this.props.showEditTripFields(this.state.tripsToshow[key]["id"]);
                                }}
                              >
                                Editar
                              </button>
                            ) : null}
                          </td>
                          <td style={{ width: "5%", textAlign: "right", height: "50px" }}>
                            {" "}
                            {{} ? (
                              <button
                                className="btn btn-outline-secondary"
                                style={{ fontSize: "10px", height: "100%" }}
                                onClick={(event) => {
                                  this.props.hideTrip(this.state.tripsToshow[key]["id"], true);
                                }}
                              >
                                Borrar
                              </button>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
                  }
  
        return (
          <div id= "content" className="align-items-center">

                                <form  onSubmit={(event) => {
            event.preventDefault()
            this.props.actualizarEstadoYAlmacenar()
            this.props.showCreateTripFields()}}>
        <br/>
            <button type="submit" className='btn btn-primary btn-block btn-lg w-30 fs-16'>
              Crear viaje
            </button>
          </form>
                    {displayList}
          </div>
        )
      }
    }catch(err){
      return(<div>Error al cargar la lista de viajes</div>)
    }
  }
}

export default TripsListTotal;