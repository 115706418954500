import React, { Component, createRef } from "react";
import DatePicker from "react-datepicker";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

class CMRCreation extends Component {
  constructor(props) {
    super(props);
    this.creationRef = createRef();
    this.state = {
      startDate: new Date(),
      tripDate: new Date(),
      endDate: new Date(),
      verifier: "0x35386EEf4f0114Aa603Ec2E42FD00275f385C838",
      time: { label: "06", value: 6 },
      bufferday: 7,
      options: ["0x35386EEf4f0114Aa603Ec2E42FD00275f385C838", "0x37024244E62F2B430e7B0eA207373076d66C435b", "0xBdc50027c1CC234C6f30838656D969365de91a2b"],
      defaultOption: "",
      payday: 7,
      tripdate: 0,
      origin: "",
      destination: "",
      alreadyLoaded: false,
      payWithCard: false,
      reloads: 0,
      askCheckout: "",
    };
    this.handleChangeTrip = this.handleChangeTrip.bind(this);
    this.handleChangeBuff = this.handleChangeBuff.bind(this);
    this.handleChangePay = this.handleChangePay.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleChangeVer = this.handleChangeVer.bind(this);
    this.handleChangeCard = this.handleChangeCard.bind(this);
  }
  componentDidMount() {
    // Desplazarse automáticamente al componente cuando se monta
    this.creationRef.current.scrollIntoView({ behavior: "smooth" });
  }

  async handleChangeTrip(dateTripp) {
    try {
      this.setState({ tripDate: dateTripp });

      let rowCurrentDate = await new Date();
      let currentDate = await Date.parse(rowCurrentDate);

      // if (dateTripp < currentDate) {
      //   alert("Por favor, por una fecha en el futuro");
      // } else {
        let formattedDate = await Date.parse(dateTripp);

        let trip = await Math.round((formattedDate - currentDate) / (3600000 * 24));

        await this.setState({
          tripdate: trip,
        });
      // }
    } catch (err) {
      alert("No puedes hacer eso");
    }
  }

  async setTime(newTime) {
    try {
      if (this.state.tripDateInt === 0) {
        // Si la fecha es 1970/01/01, se llama a handleChangeTrip para actualizarla
        await this.handleChangeTrip(this.state.tripDate);
      }

      await this.setState({ time: newTime });

      let hora = newTime.value * 3600;
      // let newTripDate = this.state.tripDateInt + hora;
      // await this.setState({ tripDateInt: newTripDate });
    } catch (err) {
      this.props.showError();
    }
  }

  async handleChangeBuff(dateBuff) {
    try {
      this.setState({
        startDate: dateBuff,
      });
      let rowCurrentDate = await new Date();
      let currentDate = await Date.parse(rowCurrentDate);
      let formattedDate = await Date.parse(dateBuff);
      let buffer = await Math.round((formattedDate - currentDate) / (3600000 * 24));

      await this.setState({
        bufferday: buffer,
      });
    } catch (err) {
      alert("No puedes hacer eso");
    }
  }

  async handleChangePay(datePay) {
    try {
      this.setState({
        endDate: datePay,
      });
      let rowCurrentDate = await new Date();
      let currentDate = await Date.parse(rowCurrentDate);
      let formattedPayDate = await Date.parse(datePay);
      let pay = await Math.round((formattedPayDate - currentDate) / (3600000 * 24));

      await this.setState({
        payday: pay,
      });
    } catch (err) {
      alert("No puedes hacer eso");
    }
  }

  async handleChangeVer(ver) {
    try {
      ver = await ver.value;
      await this.setState({
        verifier: ver,
      });
    } catch (err) {
      alert("No puedes hacer eso");
    }
  }

  async handleChangeCard() {
    try {
      var checked = this.state.payWithCard;
      if (checked === true) {
        await this.setState({ payWithCard: false });
      } else {
        await this.setState({ payWithCard: true });
      }
    } catch (err) {
      alert("No puedes hacer eso");
    }
  }

  onFormSubmit(e) {
    e.preventDefault();
  }

  render() {
    let creation;

    if (this.props.device === "desktop") {
      creation = (
        <div id="content" className="mt-3" ref={this.creationRef}>
          <div className="card mb-4">
            <div className="card-body w-100">
              <form
                className="mb-3 w-100"
                onSubmit={(event) => {
                  try {
                    event.preventDefault();
                    // create random number between 1 and 10000000000000000
                    let random = Math.floor(Math.random() * 10000000000000000);
                    // parse random as string
                    let randomString = random.toString();
                    let stringRef = randomString;
                    // let bufferDay = this.state.bufferday;
                    // let payDay = this.state.payday;

                    // let amount7;
                    // if (this.state.noWallet === false) {
                    //   amount7 = this.amount7.value.toString();
                    //   amount7 = window.web3.utils.toWei(amount7, "Ether");
                    // } else {
                    //   amount7 = this.amount7.value;
                    // }
                    // let verifier = this.state.verifier;

                    let origin = this.origin.value;
                    let destination = this.destination.value;
                    let tripDate = this.state.tripdate;
                    console.log(this.masa.value)
                    let masa = parseInt(this.masa.value);
                    console.log(masa)
                    //let masa = this.masa.value
                    let client = this.client.value
                    // let payWithCard = this.state.payWithCard;
                    // let minPay = this.minPay.value;

                    this.props.createTrip(client, masa, this.state.time, 0, "", origin, destination, tripDate, "", 0,);
                  } catch (err) {
                    alert("No puedes hacer eso");
                  }
                }}
              >
                <div className="w-100">
                  <table className="w-100">
                    <tbody className="w-100">
                      <tr className="w-100">
                        <td className="w-35">
                          <label className="float-left">
                            <b>Crear servicio</b>
                          </label>
                        </td>
                        <td className="w-40"></td>
                        <td className="w-25 text-end">
                          <span className="text-muted w-100 text-end"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* <table className=" table-borderless text-muted text-left">
                  <tbody>
                    <tr>
                      <td>Precio</td>
                      <td style={{ width: "20px" }}></td>
                      <td>
                        <span className="text-muted">Fecha de servicio</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          ref={(amount7) => {
                            this.amount7 = amount7;
                          }}
                          className="form-control form-control-lg"
                          placeholder="0"
                          required
                        />
                      </td>
                      <td style={{ width: "20px" }}></td>
                      <td>
                        <div className="input-group mb-4">
                          <DatePicker
                            className="form-control form-control-lg"
                            selected={this.state.tripDate}
                            dateFormat="yyyy/MM/dd"
                            onChange={this.handleChangeTrip}
                            placeholder="0"
                            required
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span className="text-muted">Origen</span>
                      </td>
                      <td style={{ width: "20px" }}></td>
                      <td>
                        <span className="text-muted">Destino</span>
                      </td>
                    </tr>
                    <tr></tr>

                    <tr>
                      <td>
                        <div className="input-group mb-4">
                          <input
                            ref={(origin) => {
                              this.origin = origin;
                            }}
                            className="form-control form-control-lg"
                            // placeholder="0"
                            required
                          />
                        </div>
                      </td>
                      <td style={{ width: "20px" }}></td>
                      <td>
                        <div className="input-group mb-4">
                          <input
                            ref={(destination) => {
                              this.destination = destination;
                            }}
                            className="form-control form-control-lg"
                            // placeholder="0"
                            required
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td style={{ width: "20px" }}></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Periodo de reclamaciones</td>
                      <td style={{ width: "20px" }}></td>
                      <td>Periodo de pago</td>
                    </tr>
                    <tr>
                      <td>
                        <DatePicker
                          className="form-control form-control-lg"
                          selected={this.state.startDate}
                          onChange={this.handleChangeBuff}
                          dateFormat="yyyy/MM/dd"
                          placeholder="0"
                          required
                        />
                      </td>
                      <td style={{ width: "20px" }}></td>
                      <td>
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={this.handleChangePay}
                          dateFormat="yyyy/MM/dd"
                          className="form-control form-control-lg"
                          placeholder="0"
                          required
                        />
                      </td>
                    </tr>
                    <br></br>
                    <tr>
                      <td>Pago mínimo a transportista</td>

                      <td style={{ width: "20px" }}></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          ref={(minPay) => {
                            this.minPay = minPay;
                          }}
                          className="form-control form-control-lg"
                          placeholder="0"
                          required
                        />
                      </td>
                      <td></td>
                      <td>
                        <div></div>
                      </td>
                      <td></td>
                    </tr>

                    <br></br>
                  </tbody>
                </table> */}
                <table className="table-borderless text-muted text-left">
                  <tbody>
                    <tr>
                      <td>
                        <span className="text-muted">Fecha de servicio</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="input-group mb-4">
                          <DatePicker
                            className="form-control form-control-lg"
                            selected={this.state.tripDate}
                            dateFormat="yyyy/MM/dd"
                            onChange={this.handleChangeTrip}
                            placeholder="0"
                            required
                          />
                        </div>
                      </td>
                      <td className="w-20p"></td>
                      

                      <td>
                        <div className="input-group mb-4">

                      <Dropdown
                            className='h-100 w-100'
                            value={this.state.time}
                            options={[{label: "00" , value:0},
                                      {label: "01" , value:1},
                                      {label: "02" , value:2},
                                      {label: "03" , value:3},
                                      {label: "04" , value:4},
                                      {label: "05" , value:5},
                                      {label: "06" , value:6},
                                      {label: "07" , value:7},
                                      {label: "08" , value:8},
                                      {label: "09" , value:9},
                                      {label: "10" , value:10},
                                      {label: "11" , value:11},
                                      {label: "12" , value:12},
                                      {label: "13" , value:13},
                                      {label: "14" , value:14},
                                      {label: "15" , value:15},
                                      {label: "16" , value:16},
                                      {label: "17" , value:17},
                                      {label: "18" , value:18},
                                      {label: "19" , value:19},
                                      {label: "20" , value:20},
                                      {label: "21" , value:21},
                                      {label: "22" , value:22},
                                      {label: "23" , value:23}]}
                            onChange={(newValue) => {
                            this.setTime(newValue);
                          }}
                //renderInput={(params) => <TextField {...params} />}
                                      />

                        </div>
                      </td>



                    </tr>
                    <tr>
                      <td>
                        <span className="text-muted">Origen</span>
                      </td>
                      <td className="w-20p"></td>
                      <td>
                        <span className="text-muted">Destino</span>
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>
                        <div className="input-group mb-4">
                          <input
                            ref={(origin) => {
                              this.origin = origin;
                            }}
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                      </td>
                      <td className="w-20p"></td>
                      <td>
                        <div className="input-group mb-4">
                          <input
                            ref={(destination) => {
                              this.destination = destination;
                            }}
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="text-muted">Masa de la carga en kg</span>
                      </td>
                      <td className="w-20p"></td>
                      <td>
                      <span className="text-muted">Cliente</span>
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>
                        <div className="input-group mb-4">
                          <input
                            ref={(masa) => {
                              this.masa = masa;
                            }}
                            className="form-control form-control-lg"
                            required
                            
                          />
                        </div>
                      </td>
                      <td className="w-20p"></td>
                      <td>
                        <div className="input-group mb-4">
                        <input
                            ref={(client) => {
                              this.client = client;
                            }}
                            className="form-control form-control-lg custom-placeholder"
                            placeholder="Añade correo o electronico o deja este campo vacío si es tu propio viaje"
                            defaultValue="" 
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <button type="submit" className="btn btn-primary btn-block btn-lg w-100">
                  Crear
                </button>
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      creation = (
        <div id="content" className="mt-3" ref={this.creationRef}>
          <div className="card mb-4">
            <div className="card-body w-100">
              <form
                className="mb-3 w-100"
                onSubmit={(event) => {
                  try {
                    event.preventDefault();
                    // create random number between 1 and 10000000000000000
                    let random = Math.floor(Math.random() * 10000000000000000);
                    // parse random as string
                    let randomString = random.toString();
                    let stringRef = randomString;
                    // let bufferDay = this.state.bufferday;
                    // let payDay = this.state.payday;

                    // let amount7;
                    // if (this.state.noWallet === false) {
                    //   amount7 = this.amount7.value.toString();
                    //   amount7 = window.web3.utils.toWei(amount7, "Ether");
                    // } else {
                    //   amount7 = this.amount7.value;
                    // }
                    // let verifier = this.state.verifier;

                    let origin = this.origin.value;
                    let destination = this.destination.value;
                    let tripDate = this.state.tripdate;
                    let client = this.client.value;
                    // let payWithCard = this.state.payWithCard;
                    // let minPay = this.minPay.value;

                    this.props.createTrip(client, 0, 0, 0, "", origin, destination, tripDate, "", 0);
                  } catch (err) {
                    alert("No puedes hacer eso");
                  }
                }}
              >
                <div className="w-100">
                  <table className="w-100">
                    <tbody className="w-100">
                      <tr className="w-100">
                        <td className="w-35">
                          <label className="float-left">
                            <b>Crear servicio</b>
                          </label>
                        </td>
                        <td className="w-40"></td>
                        <td className="w-25 text-end">
                          <span className="text-muted w-100 text-end"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <table className="table-borderless text-muted text-left">
                  <tbody>
                    <tr>
                      <td>
                        <span className="text-muted">Fecha de servicio</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="input-group mb-4">
                          <DatePicker
                            className="form-control form-control-lg"
                            selected={this.state.tripDate}
                            dateFormat="yyyy/MM/dd"
                            onChange={this.handleChangeTrip}
                            placeholder="0"
                            required
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="text-muted">Origen</span>
                      </td>
                      <td className="w-20p"></td>
                      <td>
                        <span className="text-muted">Destino</span>
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>
                        <div className="input-group mb-4">
                          <input
                            ref={(origin) => {
                              this.origin = origin;
                            }}
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                      </td>
                      <td className="w-20p"></td>
                      <td>
                        <div className="input-group mb-4">
                          <input
                            ref={(destination) => {
                              this.destination = destination;
                            }}
                            className="form-control form-control-lg"
                            required
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="text-muted">Cliente</span>
                      </td>
                      <td className="w-20p"></td>
                      <td>
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>
                        <div className="input-group mb-4">
                          <input
                            ref={(client) => {
                              this.client = client;
                            }}
                            className="form-control form-control-lg"
                            placeholder="Deja este campo vacío si es tu propio viaje"
                            defaultValue=""
                          />
                        </div>
                      </td>
                      <td className="w-20p"></td>
                      <td>
                        <div className="input-group mb-4">
                          
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <button type="submit" className="btn btn-primary btn-block btn-lg w-100">
                  Crear
                </button>
              </form>
            </div>
          </div>
        </div>
      );
    }

    return <div>{creation}</div>;
  }
}

export default CMRCreation;
