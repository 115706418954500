import React from "react";
import Chart from "chart.js/auto";

class PerWeekChart extends React.Component {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        const startDate = new Date();
        startDate.setMonth(currentDate.getMonth() - 4);

        this.state = {
            startDate: startDate,
            endDate: new Date(),
            filteredData: []
        };
        this.chartRef = React.createRef();
        this.chartType = "bar";
        this.chartTitle = "Ratio de trabajo por conductor";
    }

    async componentDidMount() {
        await this.filterData();
        await this.createBarChart();
    }

    filterData = async () =>  {
        const { dataGraphic } = this.props;
        const { startDate, endDate } = this.state;
        const filteredData = dataGraphic.map(employee => {
            // Filter each employee's data within the date range
            const filteredWeeks = employee.data.filter(week => {
                const initDate = new Date(week.init);
                const termDate = new Date(week.term);
                return initDate >= startDate && termDate <= endDate;
            });
            // Return employee data with filtered weeks
            return { ...employee, data: filteredWeeks };
        }).filter(employee => employee.data.length > 0); // Keep only employees with data in the selected range
        await this.setState({ filteredData: filteredData});
        await this.createBarChart()
    }

    createBarChart = async () => {
        const { filteredData } = this.state;
        const popCanvas = this.chartRef.current.getContext("2d");
    
        if (this.chartInstance) {
            this.chartInstance.destroy();
        }
    
        // Collect all unique weeks
        const weeks = new Set();
        filteredData.forEach(employee => {
            employee.data.forEach(week => {
                weeks.add(week.init);
            });
        });
    
        // Sort weeks
        const sortedWeeks = Array.from(weeks).sort();
    
        // Aggregate total working hours per week
        const ratioPerWeek = sortedWeeks.map(week => {
            let totalWorkingHours = 0;
            let totalDrivingHours = 0;
    
            filteredData.forEach(employee => {
                const weekData = employee.data.find(w => w.init === week);
                if (weekData) {
                    totalWorkingHours += weekData.working_hours;
                    totalDrivingHours += weekData.driving_hours;
                }
            });
    
            // Compute the ratio (ensure no division by zero)
            return totalDrivingHours > 0 ? totalWorkingHours / totalDrivingHours : 0;
        });
        

        console.log(ratioPerWeek)

        // Create the dataset for the chart
        const dataset = {
            label: 'Ratio of Working Hours to Driving Hours',
            data: ratioPerWeek,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
        };
    
        this.chartInstance = new Chart(popCanvas, {
            type: this.chartType,
            data: {
                labels: sortedWeeks,
                datasets: [dataset], // Dataset representing the ratio per week
            },
            
            options: {
    
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        width: '100%',
                        display: false,
                        position: 'left',
                        align: 'start',
                        labels: {
                            boxWidth: 20,
                            padding: 10,
                            usePointStyle: true,
                        },
                        maxItems: 3,
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Conductores',
                        },
                        type: 'category', 
                        position: 'bottom',
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Ratio de trabajo',
                        },
                        type: 'linear',
                        position: 'left',
                    },
                },
            },
        });
    };

    updateChart = async  () => {
        await this.filterData();
    }

    render() {

        return (
            <div>               
                <canvas ref={this.chartRef} className="w-70-vh h-70-vh"/>
            </div>
        );
    }
}

export default PerWeekChart;