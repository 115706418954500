import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem("TraxainUserToken"));
  const handleLogout = () => {
    ////console.log("logout")
    let estadoUsuario = localStorage.getItem("TraxainUserToken");
    setToken(null);
    comprobarEstado();

    function comprobarEstado() {
      ////console.log()
        if (estadoUsuario === "null" || estadoUsuario === null) {
          ////console.log("a ver si detecta mi coxiooooonnn....")
          if (navigator.onLine) {
            if (Math.random()>0.5){
              setTimeout(() => {
                window.location.href = 'https://drive-team.es';
              }, 2)
            }else{
              setTimeout(() => {
                window.location.href = 'https://www.drive-team.es';
              }, 2)
      
            }
        } 
        ////console.log("no detectado...")
            
        }
        else {
            estadoUsuario = localStorage.getItem("TraxainUserToken");
            setTimeout(() => comprobarEstado(), 50);
        }
    }
  }

  //ENDPOINTS

  //let endpointlocal = "https://api.ecargo.link/"
  let endpointlocal = "https://api.traxain.xyz/"
  let endpoint = "https://api.traxain.xyz/"
  //let endpoint2 = "https://api.traxain.xyz/"
  let endpoint2 = "https://api.ecargo.link/"
  

  
  
  useEffect(() => {
      const fetchUser = async () => {
        
        const requestOptions = await {
          method: "GET",
          headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer "+ token
          },
        };

        
        if(token === null) {
            setToken(null);
        } 
        var response
        if(token === "null"){
                        
              const requestOptions = await {
                method: "GET",
                headers: { 
                "Content-Type": "application/json",
                },
              };
             
              try{
                ////console.log("wanna try endpointlocal")
                response = await fetch(endpointlocal + "api/users/visitaWeb", requestOptions);
                ////console.log(response.ok)
                if (!response.ok) {
                  throw new Error('Request failed with status ');
                }else{
                  // //console.log("UserContext de endpointlocal")
                }
              }catch (err) {
                ////console.log("UserContext: Fallo en endpointlocal")
                try{
                    response = await fetch(endpoint + "api/users/visitaWeb", requestOptions);
                    ////console.log(response.ok)
                    if (!response.ok) {
                      throw new Error('Request failed with status ');
                    }else{
                      //console.log("UserContext de endpoint")
                    }
                }catch (err) {
                  // //console.log("UserContext: Fallo en endpoint")
                  try{
                    response = await fetch(endpoint2 + "api/users/visitaWeb", requestOptions);
                    if (!response.ok) {
                      throw new Error('Request failed with status ');
                    }else{
                      //console.log("UserContext de endpoint2")
                    }
                  }catch (err) {
                    //console.log("UserContext: Fallo en endpoint2");         
                  }


                }
              }
            
         // accesoWeb();
                    
        }else {
          ////console.log("wanna try endpointlocal")
          try{
            response = await fetch(endpointlocal + "api/users/visitaWeb", requestOptions);
            ////console.log(response.ok)
            if (!response.ok) {
              throw new Error('Request failed with status ');
            }else{
               ////console.log("UserContext de endpointlocal")
            }
          }catch (err) {
             ////console.log("UserContext: Fallo en endpointlocal")
            try{
                response = await fetch(endpoint + "api/users/visitaWeb", requestOptions);
                if (!response.ok) {
                  throw new Error('Request failed with status ');
                }else{
                   ////console.log("UserContext de endpoint")
                }
            }catch (err) {
              // //console.log("UserContext: Fallo en endpoint")
              try{
                response = await fetch(endpoint2 + "api/users/visitaWeb", requestOptions);
                if (!response.ok) {
                  throw new Error('Request failed with status ');
                }else{
                   //console.log("UserContext de endpoint2")
                }
              }catch (err) {
                // //console.log("UserContext: Fallo en endpoint2");
                handleLogout()         
              }


            }
          }
         
          }


            ////console.log("token: "+token)
            localStorage.setItem("TraxainUserToken", token);
            

            if (response == null || (!response.ok && response.type === 'cors')){
            ////console.log("error api/users/me")
            handleLogout()
          }
          
          
          
          
      };

    fetchUser();


  }, [token]);

  
    return (
      <UserContext.Provider value={[token, setToken ]} >
          {props.children}
      </UserContext.Provider>
    );
 
    
};
