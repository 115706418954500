import React from "react";
import Chart from "chart.js/auto";

class PerDayChartCO2 extends React.Component {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        const startDate = new Date();
        startDate.setMonth(currentDate.getMonth() - 4);

        this.state = {
            startDate: startDate,
            endDate: new Date(),
            filteredData: []
        };
        this.chartRef = React.createRef();
        this.chartType = "bar";
        this.chartTitle = "CO2 por día";
    }

    async componentDidMount() {
        await this.filterData(0);
    }

    filterData = async (iter) =>  {
        const { activities } = this.props;
        
        if (!activities && iter < 5) {
            setTimeout(() => {
                iter += 1;
                this.filterData(iter);
            }, 1000);
            }
        
        const { startDate, endDate } = this.state;
        console.log(startDate, endDate)

        const activitiesFiltered =  activities.filter(activity => {
            const activityDate = new Date(activity.start*1000);
            return activityDate >= startDate && activityDate <= endDate;
        })
        await this.setState({ filteredData: activitiesFiltered});
        await this.createBarChart()
    }


    createBarChart = async () => {
        var filteredData   = this.state

        // sort filteredData by date in ascending order
        filteredData = filteredData.filteredData.sort((a, b) => new Date(a.start*1000) - new Date(b.start*1000));

        const popCanvas = this.chartRef.current.getContext("2d");
        // group activities by date using activity.date, wich is datetime
        
        const activitiesByDate = filteredData.reduce((acc, activity) => {
            const date = new Date(activity.start*1000); // activity.date;
            // get the date's day and month in thos manner month-day, from the datetime
            const day = `${date.getMonth() + 1}-${date.getDate()}`;
           
            if (!acc[day]) {
                acc[day] = [];
            }
            acc[day].push(activity);
            return acc;
        }, {});

        console.log(activitiesByDate)

        let sum_co2_by_date = []
        let empty_co2_by_date = []
        let full_co2_by_date = []
        
        let dates = Object.keys(activitiesByDate)



        for (let date in activitiesByDate) {
            let sum_co2 = 0
            let empty_co2 = 0
            let full_co2 = 0
            activitiesByDate[date].forEach(activity => {
                sum_co2 += activity.co2
                empty_co2 += activity.empty_co2
                full_co2 += activity.full_co2
            });
            sum_co2_by_date.push(sum_co2/100)
            empty_co2_by_date.push(empty_co2/100)
            full_co2_by_date.push(full_co2/100)
        }

        console.log(sum_co2_by_date)



        const datasets = [{
            label: 'CO2 Lleno',
            data: full_co2_by_date,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
        },
        {
            label: 'CO2 En vacío',
            data: empty_co2_by_date,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
        },
        {
            label: 'CO2 Efectivo',
            data: sum_co2_by_date,
            backgroundColor: 'rgba(10, 37, 162, 0.8)',
            borderColor: 'rgba(10, 37, 162, 0.8)',
            borderWidth: 1,
        }
    ];
    
        this.chartInstance = new Chart(popCanvas, {
            type: this.chartType,
            data: {
                labels: dates,
                datasets: datasets, // Dataset representing the ratio per week
            },
            
            options: {
    
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                   
                            legend: {
                                width: '100%',
                                display: true,
                                position: 'bottom',
                                align: 'start',
                                labels: {
                                    boxWidth: 20,
                                    padding: 10,
                                    usePointStyle: true,
                                },
                                maxItems: 3,
                            },
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: 'Día del mes',
                                    },
                                    type: 'category', 
                                    position: 'bottom',
                                },
                                y: {
                                    title: {
                                        display: true,
                                        text: 'CO2',
                                    },
                                    type: 'linear',
                                    position: 'left',
                                },
                            },
                        },              
                    }
                }
            );
    };

    updateChart = async  () => {
        await this.filterData(0);
    }

    render() {

        return (
            <div>               
                <canvas ref={this.chartRef} className="w-70-vh h-70-vh"/>
            </div>
        );
    }
}

export default PerDayChartCO2;